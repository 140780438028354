// pen

/* eslint-disable */

export default {
  settings: {
    disableIframePostMessage: false,
  },
  content: {
    general: {
      clientName: "Pen Underwriting",
    },
    pages: {
      directorsAndOfficers: {
        id: "directors-and-officers",
        pageTitle: `Directors and Officers`,
        contactEmail: `dno@penunderwriting.com`,
        subHeader: `
          Our skilled Liability experts are keen to deliver solutions for you.
          With $20m Primary or $30m XOL limits available, and extensions
          available for product recall, E&O, CCC. We can develop exclusive
          products for existing and developing broker portfolios. We welcome
          the opportunity to discuss your clients needs.
        `,
        mainbody: `
          <h3 class="highlight mt-4">Industries & Occupations</h3>

          <section class="industry">
            <div class="industry-list row justify-content-left">
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-2">Hospitality & Accommodation</h5>
                  <ul>
                    <li>Pubs</li>
                    <li>Cafes and Restaurants (Licensed & Non & Licensed)</li>
                    <li>Accommodation Risks</li>
                    <li>Licensed Clubs</li>
                    <li>Boarding Houses/ Hostels</li>
                    <li>Caravan Parks</li>
                    <li>Backpackers</li>
                    <li>Theatres</li>
                  </ul>
                </div>
              </div>

              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-2">Manufacturing</h5>
                  <ul>
                    <li>Food, Beverage Manufacturing</li>
                    <li>Machinery And Equipment Manufacturing</li>
                    <li>Metal Product Manufacturing</li>
                    <li>Non-Metallic Mineral Product Manufacturing</li>
                    <li>Plastics & Rubber Manufacturing</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-2">General Construction</h5>
                  <ul>
                    <li>Residential & Non-Residential Building</li>
                    <li>Roads & Bridges</li>
                    <li>Erection/ Installation Tanks & Silos</li>
                    <li>Urban & Suburban transport infrastructure</li>
                    <li>Renewable Energy & solar</li>
                    <li>Mining & extraction infrastructure</li>
                  </ul>
                </div>
              </div>

              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-2">Construction Trades</h5>
                  <ul>
                    <li>Site Preparation Services</li>
                    <li>Electrical Services</li>
                    <li>Construction Services</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-2">Wholesale Trade</h5>
                  <ul>
                    <li>Basic Material Wholesaling</li>
                    <li>Machinery And Motor Vehicle Wholesaling</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-2">Transport & Storage</h5>
                  <ul>
                    <li>Road Transport</li>
                    <li>Storage</li>
                    <li>Services to Transport</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-2">Mining</h5>
                  <ul>
                    <li>Services to Mining</li>
                    <li>Above Ground Mining</li>
                    <li>Other Mining</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-2">Personal & Other Services</h5>
                  <ul>
                    <li>Property Services</li>
                    <li>Business Services</li>
                  </ul>
                </div>
              </div>
              <div class="card col-12 col-sm-6 col-md-4 industry-card">
                <div class="card-body p-3">
                  <h5 class="card-title mt-2">Retail Trade</h5>
                  <ul>
                    <li>Retail Trade</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        `,
        teamDivision: "DirectorsAndOfficers",
      },
      generalLiability: {
        id: "general-liability",
        pageTitle: `Liability`,
        contactEmail: `gl@penunderwriting.com`,
        subHeader: `
            Our experienced Liability specialists are dedicated to providing tailored solutions to meet your needs. With available limits of $20 million for Primary and $30 million for Excess of Loss (XOL), along with optional extensions for Product Recall, Errors & Omissions (E&O), and Care, Custody, and Control (CCC), we can craft exclusive products designed for both established and emerging broker portfolios.<br/><br/>We look forward to discussing how we can support your clients' unique requirements.
          `,
        mainbody: `
            <h3 class="highlight mt-4">Industries & Occupations</h3>

            <section class="industry">
              <div class="industry-list row justify-content-left">
                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Hospitality & Accommodation</h5>
                    <ul>
                      <li>Pubs</li>
                      <li>Cafes and Restaurants (Licensed & Non & Licensed)</li>
                      <li>Accommodation Risks</li>
                      <li>Licensed Clubs</li>
                      <li>Boarding Houses/ Hostels</li>
                      <li>Caravan Parks</li>
                      <li>Backpackers</li>
                      <li>Theatres</li>
                    </ul>
                  </div>
                </div>
                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Manufacturing</h5>
                    <ul>
                      <li>Food, Beverage Manufacturing</li>
                      <li>Machinery And Equipment Manufacturing</li>
                      <li>Metal Product Manufacturing</li>
                      <li>Non-Metallic Mineral Product Manufacturing</li>
                      <li>Plastics & Rubber Manufacturing</li>
                    </ul>
                  </div>
                </div>
                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">General Construction</h5>
                    <ul>
                      <li>Residential & Non-Residential Building</li>
                      <li>Roads & Bridges</li>
                      <li>Erection/ Installation Tanks & Silos</li>
                      <li>Urban & Suburban transport infrastructure</li>
                      <li>Renewable Energy & solar</li>
                      <li>Mining & extraction infrastructure</li>
                    </ul>
                  </div>
                </div>

                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Construction Trades</h5>
                    <ul>
                      <li>Site Preparation Services</li>
                      <li>Electrical Services</li>
                      <li>Construction Services</li>
                    </ul>
                  </div>
                </div>
                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Wholesale Trade</h5>
                    <ul>
                      <li>Basic Material Wholesaling</li>
                      <li>Machinery And Motor Vehicle Wholesaling</li>
                    </ul>
                  </div>
                </div>
                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Transport & Storage</h5>
                    <ul>
                      <li>Road Transport</li>
                      <li>Storage</li>
                      <li>Services to Transport</li>
                    </ul>
                  </div>
                </div>
                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Mining</h5>
                    <ul>
                      <li>Services to Mining</li>
                      <li>Above Ground Mining</li>
                      <li>Other Mining</li>
                    </ul>
                  </div>
                </div>
                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Personal & Other Services</h5>
                    <ul>
                      <li>Property Services</li>
                      <li>Business Services</li>
                      <li>Personal Services</li>
                    </ul>
                  </div>
                </div>
                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Retail Trade</h5>
                    <ul>
                      <li>Retail Trade</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          `,
        teamDivision: "Liability",
      },
      professionalRisks: {
        id: "professional-risks-insurance",
        pageTitle: `Financial Lines`,
        contactEmail: `pr@penunderwriting.com`,
        subHeader: `
            Our Financial Lines team is dedicated to delivering solutions for a wide range of occupations, working closely with you to meet your specific needs. We are committed to exceptional customer service and take pride in understanding your clients’ business. Our experienced professionals focus on crafting insurance solutions that are perfectly suited to your unique requirements.
          `,
        mainbody: `
            <section class="industry">
              <div class="industry-list row justify-content-left">
                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h3 class="my-4">Professional Indemnity - Primary</h3>
                    <p>Comprehensive coverage up to $10m capacity, we specialize in serving companies with a fee income up to $5m.<br/><br/>However, we are open to considering any fee income, ensuring that businesses of all sizes can benefit from our expertise.</p>
                    <h5>Preferred risks</h5>
                    <ul>
                      <li>Architects</li>
                      <li>Engineers (Civil, Structural) </li>
                      <li>IT Consultants (No Cyber Security or MSP)</li>
                      <li>Project Managers </li>
                      <li>Real Estate</li>
                      <li>Surveyors (Land, Quantity)</li>
                    </ul>
                    <h5>Limited appetite</h5>
                    <ul>
                      <li>Surveyors (Building)</li>
                      <li>Engineers (Chemical, Structural)</li>
                    </ul>
                  </div>
                </div>

                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h3 class="my-4">Professional Indemnity - Excess</h3>
                    <p>
                      Follow form Wording, with local security, up to up to $20m capacity.
                    </p>
                    <p>
                      We can accommodate any size firm, and we can deploy more capacity than the primary.
                    </p>
                    <h5>Preferred risks</h5>
                    <ul>
                      <li>Construction PI – D&C </li>
                      <li>Mining risks</li>
                      <li>Large engineers</li>
                      <li>Law Firms</li>
                    </ul>
                  </div>
                </div>

                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h3 class="my-4">D&O</h3>
                    <h5>D&O cover specifically targeted at the mining and resource industry.</h5>
                    <ul>
                      <li>Junior miners, opportunistic, producers</li>
                      <li>Private and listed companies</li>
                      <li>Primary and XOL</li>
                    </ul>
                    <h5>Coal related operations are outside appetite</h5>
                  </div>
                </div>

              </div>
              <div class="row industry-list mt-4">
                <div class="card col col-md-12 industry-card">
                  <div class="card-body p-3">
                    <h3 class="my-4">Cyber</h3>
                    <h5 class="card-title mt-2">Broad appetite and coverage for businesses up to 450m turnover</h5>
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <p class="">
                          <h5 class="card-title mt-2">Coverage</h5>
                          <ul>
                            <li>Breach response costs </li>
                            <li>Cyber Business Interruption</li>
                            <li>Extortion and Ransomware</li>
                            <li>Privacy Liability/Network Security</li>
                            <li>Cyber Crime</li>
                            <li>Credit monitoring/Identity Theft</li>
                            <li>Public relations expenses</li>
                          </ul>
                        </p>
                      </div>
                      <div class="col-12 col-md-6">
                        <p>
                          <h5 class="card-title mt-2">Preferred risks</h5>
                          <ul>
                            <li>Architects &amp; Engineers</li>
                            <li>Charities &amp; Not for profit</li>
                            <li>Construction</li>
                            <li>Domestic Services</li>
                            <li>Education</li>
                            <li>Financial Institutions</li>
                            <li>Government</li>
                            <li>Hospitality</li>
                            <li>Logistics &amp; Freight Forwarder</li>
                            <li>Manufacturing</li>
                            <li>Real Estate</li>
                            <li>Recruitment</li>
                            <li>Retail &amp; Wholesale</li>
                          </ul>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          `,
        teamDivision: "Financial Lines",
      },
      careLiability: {
        id: "care-liability-insurance",
        pageTitle: `Care Liability`,
        contactEmail: `careliability@penunderwriting.com`,
        subHeader: `
            Our Care team is passionate about protecting the businesses that care for our most vulnerable. We offer combined liability packages designed specifically for Aged Care and Disability Care providers. Reach out to our experts to discuss your client’s needs.
          `,
        mainbody: `
            <h3 class="highlight mt-4">Industries & Occupations</h3>

            <section class="industry">
              <div class="industry-list row justify-content-left">
                <div class="card col-12 col-md-6 industry-card">
                  <div class="card-body p-3">
                    <h3 class="card-title mt-2">Aged Care</h3>
                    <ul>
                      <li>Residential Aged Care Facilities</li>
                      <li>Retirement living and Independent living units</li>
                      <li>At Home Nursing Care</li>
                    </ul>
                  </div>
                </div>
                <div class="card col-12 col-md-6 industry-card">
                  <div class="card-body p-3">
                    <h3 class="card-title mt-2">Disability Support Providers</h3>
                    <ul>
                      <li>Personal Care</li>
                      <li>At home nursing care</li>
                      <li>Permanent, short stay disability accommodation</li>
                      <li>Companionship & transportation</li>
                      <li>Australian Disability Enterprises</li>
                    </ul>
                  </div>
                </div>
              </div>

              <h3 class="highlight mt-4">Our Industry Specific Products:</h3>

              <div class="row industry-list">
                <div class="card col-12 industry-card">
                  <div class="card-body p-3">
                    <ul>
                      <li>
                        General Liability ($50m on Aged Care, $20m on Disability
                        providers)
                      </li>
                      <li>Professional Malpractice Liability ($20m)</li>
                      <li>Management Liability ($20m)</li>
                    </ul>
                  </div>
                </div>
              </div>

              <h3 class="highlight mt-4">Outside appetite</h3>

              <div class="row industry-list">
                <div class="card col industry-card exclusion">
                  <div class="card-body p-3">
                    <ul>
                      <li>
                        Foster Care services and/or transitional accommodation
                      </li>
                      <li>
                        Permanent Accommodation for disabled persons under 18
                        years of age
                      </li>
                      <li>
                        Providers where there majority of clients are under 18
                        years of age
                      </li>
                      <li>Drug and alcohol rehabilitation centres</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          `,
        teamDivision: "Care Liability",
      },
      transport: {
        id: "transport-insurance",
        pageTitle: `Transport`,
        contactEmail: `transport@penunderwriting.com`,
        subHeader: `
            Our Transport team brings expertise and quality underwriting to ensure you receive the best possible coverage options. Whether it's hire cars or commercial long-haul fleets, the Pen Transport team offers standard, nonstandard, and specialty commercial motor insurance tailored to your clients’ needs.
          `,
        mainbody: `
            <h3 class="highlight mt-4">Coverage</h3>

            <section class="industry">
              <div class="industry-list row justify-content-left">
                <div class="card col-12 industry-card">
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <ul>
                          <li>Agreed value option available</li>
                          <li>Up to $750k per vehicle combination for heavy vehicles</li>
                          <li>Legal Liability up to $30m</li>
                          <li>Extensions available up to $5m for Dangerous Goods</li>
                          <li>Choice of repairer</li>
                        </ul>
                      </div>
                      <div class="col-12 col-md-6">
                        <ul>
                          <li>
                            2 year new for old replacement for vehicles > 3.5 tonnes (total loss only)
                          </li>
                          <li>Personal Effects / Driver property $2,500</li>
                          <li>Up to $5,000 signwriting</li>
                          <li>Excess waived for sedans and light vehicles not at fault</li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="row d-none d-md-flex">
                <div class="col-12 col-md-7">
                  <h3 class="highlight mt-4">What’s in appetite</h3>
                </div>
                <div class="col-12 col-md-5">
                  <h3 class="highlight mt-4">What’s not</h3>
                </div>
              </div>
              <div class="industry-list row justify-content-left">
                <div class="card col-12 col-md-7 industry-card inclusion ">
                  <h3 class="px-3 highlight mt-4 d-md-none">What’s in appetite</h3>
                  <div class="card-body p-3">
                    <ul>
                      <li>Heavy vehicle owner driver operations</li>
                      <li>Heavy vehicle fleets </li>
                      <li>Small to large light commercial fleets</li>
                      <li>Goods carrying vehicles</li>
                      <li>Hire vehicles</li>
                      <li>We will consider new ventures</li>
                      <li>Mixed fleets including trucks, machinery and commercial vehicles across a range of industries including manufacturing, construction, logistics, trades</li>
                      <li>Couriers (minimum 10 units)</li>
                    </ul>
                  </div>
                </div>

                <div class="card col-12 col-md-5 industry-card exclusion">
                  <h3 class="px-3 highlight mt-4 d-md-none">What’s not</h3>
                  <div class="card-body p-3">
                    <ul>
                      <li>Taxi or ride share</li>
                      <li>Limos</li>
                      <li>Livestock cartage</li>
                      <li>Logging</li>
                      <li>Bulk Dangerous Goods</li>
                      <li>Long haul refrigerated</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          `,
        teamDivision: "Transport",
      },
      hospitality: {
        id: "hospitality-insurance",
        pageTitle: `Hospitality`,
        contactEmail: `hospitality@penunderwriting.com`,
        subHeader:
          "Lorem ipsum dolor sit amet, consectetur adipisifwcing elit, sed do eiusmod tempor incididunt ut labore et dolore roipi magna aliqua.",
        description1:
          "Lorem ipsum dolor sit amet, consectetur adipisifwcing elit, sed do eiusmod tempor incididunt ut labore et dolore roipi magna aliqua. Ut enim ad minim veeniam, quis nostruklad exercitation ullamco laboris nisi ut",
        description2:
          "Lorem ipsum dolor sit amet, consectetur adipisifwcing elit, sed do eiusmod tempor incididunt ut labore et dolore roipi magna aliqua. Ut enim ad minim veeniam, quis nostruklad exercitation ullamco laboris nisi ut",
        cursiveHeader: "Our Risk Appetite",
        cursiveSubHeader: "Industry types ranging from:",
        industryTypes: `
          <li>Property Owners (both Commercial & Industrial)</li>
          <li>Single and/or multi locations</li>
          <li>Hospitality</li>
          <li>Tyre Retailing & Wholesaling</li>
          <li>Boarding Houses/ Backpackers</li>
          <li>Product Manufacturing (Light & Heavy)</li>
          <li>Food Industries</li>
          <li>Warehousing</li>
        `,
        teamDivision: "Hospitality",
      },
      construction: {
        id: "construction-insurance",
        pageTitle: `Construction`,
        contactEmail: `construction@penunderwriting.com`,
        subHeader: `
            Our highly experienced Construction team is eager to assist you in securing the optimal coverage for your clients. We offer Single Project and Annual Contracts policies (“contracts commencing” or “transfer” basis) with limits up to $30 million.
          `,
        mainbody: `
            <section class="industry my-4">
              <div class="industry-list row justify-content-left">
                <div class="card col-12 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Building</h5>
                    <ul>
                      <li>Commercial Developments</li>
                      <li>Industrial Developments</li>
                    </ul>
                  </div>
                </div>
                <div class="card col-12 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Civil</h5>
                    <ul>
                      <li>Site preparation</li>
                      <li>Sub-divisions</li>
                      <li>Infrastructure</li>
                      <li>Roads & bridges</li>
                      <li>Transportation</li>
                      <li>Harbour works</li>
                      <li>Mining</li>
                    </ul>
                  </div>
                </div>
                <div class="card col-12 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Erection</h5>
                    <ul>
                      <li>Structural steel</li>
                      <li>Mechanical installations</li>
                      <li>Pipelines</li>
                      <li>Renewable power</li>
                    </ul>
                  </div>
                </div>
              </div>


              <div class="row industry-list">
                <div class="card col industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Section 2 Liability</h5>
                    <ul>
                      <li>
                        We can offer Section 2 Liability up to $20m on most risks
                        across building, civil and erection (up to 5 storeys
                        outside the CBD)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          `,
        teamDivision: "Construction",
      },
      property: {
        id: "property-insurance",
        pageTitle: `Property`,
        contactEmail: "property@penunderwriting.com",
        subHeader: `
          Our team of Property experts are dedicated to working with you to provide effective solutions and outstanding service. Experience Pen Underwriting’s unmatched expertise in providing tailored coverage and competitive pricing for complex medium-hazard risks.<br/><br/>We offer capacity up to $35 million on a 100% lead or follow basis.
          `,
        mainbody: `
            <h3 class="highlight mt-4">Industries & Occupations</h3>

            <section class="industry">
              <div class="industry-list row justify-content-left">
                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Manufacturing</h5>
                    <ul>
                      <li>Food and Beverage</li>
                      <li>Machinery and Equipment</li>
                      <li>Metal Products</li>
                      <li>Plastics and Rubber, fibreglassing</li>
                      <li>Petroleum, Chemical & Associated Product</li>
                      <li>Wood and Paper Product</li>
                    </ul>
                  </div>
                </div>
                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Hospitality & Accommodation</h5>
                    <ul>
                      <li>Accommodation including boarding houses</li>
                      <li>Backpackers</li>
                      <li>Pubs</li>
                      <li>Cafes & Restaurants</li>
                      <li>Nightclubs</li>
                    </ul>
                  </div>
                </div>
                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Wholesale & Retail</h5>
                    <ul>
                      <li>Basic Material</li>
                      <li>Personal &amp; Household goods</li>
                      <li>Machinery</li>
                      <li>Motor Vehicle Parts &amp; services</li>
                    </ul>
                  </div>
                </div>

                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Property Owners</h5>
                    <ul>
                      <li>Property owners of single and multi-tenanted risks</li>
                      <li>Vacant properties</li>
                      <li>Mixed properties including Hospitality</li>
                    </ul>
                  </div>
                </div>
                <div class="card col-12 col-sm-6 col-md-4 industry-card">
                  <div class="card-body p-3">
                    <h5 class="card-title mt-2">Transport & Storage</h5>
                    <ul>
                      <li>Storage</li>
                      <li>Services to Transport</li>
                      <li>Warehousing</li>
                    </ul>
                  </div>
                </div>
              </div>
              <h3 class="highlight mt-4">Outside appetite</h3>
              <div class="row industry-list">
                <div class="card industry-card col exclusion">
                  <div class="card-body p-3">
                    <ul>
                      <li>Mining</li>
                      <li>Wreckers</li>
                      <li>Island Risks</li>
                      <li>Residential risks</li>
                      <li>Outlaw club houses</li>
                      <li>100% Timber risks</li>
                      <li>Transmission and Distribution lines</li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          `,
        teamDivision: "Property",
      },
    },
    products: [
      {
        id: "1",
        title: "Property",
        description:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
        imageUrl: "property",
        clickUrl: "/property-overview",
      },
      {
        id: "2",
        title: "Liability",
        description:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
        imageUrl: "general-liability",
        clickUrl: "/general-liability-overview",
      },
      {
        id: "3",
        title: "Care Liability",
        description:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
        imageUrl: "care-liability",
        clickUrl: "/overview-care-liability",
      },
      {
        id: "4",
        title: "Transport",
        description:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
        imageUrl: "transport",
        clickUrl: "/transport-overview",
      },
      {
        id: "5",
        title: "Construction",
        description:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
        imageUrl: "construction",
        clickUrl: "/construction-overview",
      },
      {
        id: "6",
        title: "Financial Lines",
        description:
          "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
        imageUrl: "professional-indemnity",
        clickUrl: "/professional-risks",
      },
    ],
    jobs: [
      {
        title: "Fruit Packing Sheds",
        type: "Property",
      },
      {
        title: "Wine Bar",
        type: "Property",
      },
      {
        title: "Electrical Equipment Wholesaler",
        type: "Property",
      },
      {
        title: "Foam Insulation Manufacturing",
        type: "Property",
      },
      {
        title: "Foam Insulation Manufacturing",
        type: "Property",
      },
      {
        title: "Hotel with attaching bottle shop",
        type: "Property",
      },
      {
        title: "Hardware Wholesalers",
        type: "Property",
      },
      {
        title: "Small Goods Wholesaler",
        type: "Property",
      },
      {
        title: "Hotel in regional town",
        type: "Property",
      },
      {
        title: "Wine Bar",
        type: "Property",
      },
      {
        title: "Engineering Works",
        type: "Property",
      },
      {
        title: "Hotel with short term accommodation",
        type: "Property",
      },
      {
        title: "Hearing Aid Manufacturing",
        type: "Property",
      },
      {
        title: "Drone Storage",
        type: "Property",
      },
      {
        title: "Injection moulding",
        type: "Property",
      },
      {
        title: "Tavern with attaching office business",
        type: "Property",
      },
      {
        title: "Licensed Pub Group",
        type: "Property",
      },
      {
        title: "Dumpling Wholesalers",
        type: "Property",
      },
      {
        title: "Mixed shopping centres with pub tenant",
        type: "Property",
      },
      {
        title: "Nightclub Operator",
        type: "Property",
      },
      {
        title: "Cabinet Maker",
        type: "Property",
      },
      {
        title: "Gin Distillery",
        type: "Property",
      },
      {
        title: "Commercial Printers",
        type: "Property",
      },
      {
        title: "3-4 Star Hotel (accommodation)",
        type: "Property",
      },

      {
        title:
          "Sales and installation of lifts and platforms for commercial and residential use",
        type: "Liability",
      },
      {
        title: "Water Treatment Services, Equipment Sales & Repairs",
        type: "Liability",
      },
      {
        title:
          "Liability for importer, retailer and fitter of tyres including re-grooving",
        type: "Liability",
      },
      {
        title:
          "Motor vehicle engineer, sales Of engine Spare parts, mechanical repairs",
        type: "Liability",
      },
      {
        title:
          "Bottling Plant and Packaging of Alcoholic and Non-Alcoholic drinks",
        type: "Liability",
      },
      {
        title:
          "Sales & Repairs on Vehicles, Machinery, Heavy Trucks And Marine equipment",
        type: "Liability",
      },
      {
        title: "Engineering company servicing the mining sectors",
        type: "Liability",
      },
      {
        title: "Import and wholesale of new automotive spare parts",
        type: "Liability",
      },
      {
        title: "Residential Building Construction",
        type: "Liability",
      },
      {
        title:
          "Large construction operations including design and construct maintenance contractors, project and construction managers",
        type: "Liability",
      },
      {
        title: "Iron Ore Mining Contractors (above ground)",
        type: "Liability",
      },
      {
        title: "Electrical Services, including Solar Works",
        type: "Liability",
      },
      {
        title: "Hotel & Bottle shop group",
        type: "Hospitality",
      },
      {
        title: "Large regional RSL club ",
        type: "Hospitality",
      },
      {
        title: "Hotel with Accommodation",
        type: "Hospitality",
      },
      {
        title: "Licensed hotel, motel & bottle shops",
        type: "Hospitality",
      },
      {
        title: "Backpackers in WA",
        type: "Hospitality",
      },
      {
        title: "Pub and brewery in regional NSW",
        type: "Hospitality",
      },
      {
        title: "Licensed wine bars and restaurant",
        type: "Hospitality",
      },
      {
        title: "Large Turnover Hotel - Single location",
        type: "Hospitality",
      },

      {
        title:
          "Combined Liability for a number of Home and Community Care businesses in VIC & NSW",
        type: "Care",
      },
      {
        title:
          "Retirement Village - Independent Living Units also includes: gymnasium, restaurant and swimming pool",
        type: "Care",
      },
      {
        title: "Disability Care provider including Social Enterprise",
        type: "Care",
      },
      {
        title:
          "Home & Community Care, Accommodation with shared housing in South Australia",
        type: "Care",
      },
      {
        title:
          "Retirement village in VIC Combined Liability for large residential aged care facility",
        type: "Care",
      },
      {
        title: "Disability Care Provider franchisees",
        type: "Care",
      },
      {
        title:
          "Large Not For Profit Aged Care Facilities also providing in home care services residential aged care facility",
        type: "Care",
      },

      {
        title: "Civil engineering, excavation and concreting in Tasmania",
        type: "Construction",
      },
      {
        title:
          "Water and sewage treatment plant construction and maintenance in SA",
        type: "Construction",
      },
      {
        title:
          "Installation of a battery energy storage facility including infrastructure",
        type: "Construction",
      },
      {
        title:
          "Earthmoving contractors, roadworks, labour hire, dry machinery hire and railway freight works in WA",
        type: "Construction",
      },
      {
        title: "Fabrication and installation of structural steel",
        type: "Construction",
      },
      {
        title: "Residential subdivision in NSW",
        type: "Construction",
      },
      {
        title:
          "Construction for large landscaping contractor including irrigation and park remediation",
        type: "Construction",
      },
      {
        title: "Construction for Roads, Subdivisions & minor Civil Contracts",
        type: "Construction",
      },
      {
        title: "Installation and commissioning of a water treatment plant",
        type: "Construction",
      },

      {
        title:
          "A multi-year, Project Specific XOL D&C policy on for a new university building",
        type: "Professional",
      },
      {
        title:
          "XOL - renewable energy risk - design, construction and project management of solar farms",
        type: "Professional",
      },
      {
        title:
          "XOL PI for large Engineering firm involved in Civil Engineering, Mechanical Engineering and Geo-technical Engineering",
        type: "Professional",
      },
      {
        title:
          "Excess PI D&C for complex construction contractor with work including asbestos & demolition",
        type: "Professional",
      },
      {
        title:
          "Excess PI for large Consulting Engineering Firm including Civil, Structural & Electrical",
        type: "Professional",
      },
      {
        title: "XOL D&C for new residential and commercial buildings",
        type: "Professional",
      },
      {
        title: "XOL PI for building inspector of temporary work structures",
        type: "Professional",
      },
      {
        title: "XOL PI for rigging, demolition and building works",
        type: "Professional",
      },

      {
        title: "Fleet for cartage of sand soil and gravel",
        type: "Transport",
      },
      {
        title: "Delivery of trucks under trade plates",
        type: "Transport",
      },
      {
        title: "Fleet for fencing contractor with one large claim",
        type: "Transport",
      },
      {
        title: "General haulage contractor",
        type: "Transport",
      },
      {
        title: "Refrigerated Transport",
        type: "Transport",
      },
      {
        title: "Fleet for vehicle repairer with loan Hire-rental",
        type: "Transport",
      },
      {
        title: "Garbage Contractor",
        type: "Transport",
      },
      {
        title: "Cartage Contractor - Grain",
        type: "Transport",
      },
      {
        title: "Tipping - Sand, Soil & Gravel",
        type: "Transport",
      },
      {
        title: "Fleet for removalist",
        type: "Transport",
      },
      {
        title: "Commercial fleet for commercial cleaning  services",
        type: "Transport",
      },
      {
        title: "Fleet for Hire/ rental operation ",
        type: "Transport",
      },
    ],
    people: [
      {
        name: "Adrian Lyons",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "03 9810 0625",
        mobileUrl: "0398100625",
        phone: "0481 248 181",
        phoneUrl: "0481248181",
        email: "Adrian_Lyons@penunderwriting.com",
        imageUrl: "Adrian-Lyons",
        location: "Melbourne",
      },
      // {
      //   name: "Alicia Kastrounis",
      //   division: "Liability",
      //   role: "Underwriter",
      //   mobile: "02 9323 5024",
      //   mobileUrl: "0293235024",
      //   phone: "0481 482 733",
      //   phoneUrl: "0481482733",
      //   email: "alicia_kastrounis@penunderwriting.com",
      //   imageUrl: "Alicia-Kastrounis",
      //   location: "Sydney",
      // },
      {
        name: "Amelia Bluett",
        division: "Care Liability",
        role: "Senior Underwriter",
        mobile: "03 9810 0605",
        mobileUrl: "0398100605",
        phone: "0439 757 668",
        phoneUrl: "0439757668",
        email: "Amelia_Bluett@penunderwriting.com",
        imageUrl: "Amelia-Bluett",
        location: "Melbourne",
      },
      {
        name: "Ashley Bunn",
        division: "Liability",
        role: "Senior Underwriter",
        mobile: "03 9810 0621",
        mobileUrl: "0398100621",
        phone: "0466 689 507",
        phoneUrl: "0466689507",
        email: "Ashley_Bunn@penunderwriting.com",
        imageUrl: "Ashley-Bunn",
        location: "Melbourne",
      },
      {
        name: "Barry Long",
        division: "Liability",
        role: "Senior Underwriter",
        mobile: "03 9810 0611",
        mobileUrl: "0398100611",
        phone: "0481 461 178",
        phoneUrl: "0481461178",
        email: "Barry_Long@penunderwriting.com",
        imageUrl: "Barry-Long",
        location: "Melbourne",
      },
      {
        name: "Benedicta Ankrah",
        division: "Liability",
        role: "Senior Underwriter",
        mobile: "02 9323 5037",
        mobileUrl: "0293235037",
        phone: "0418 900 310",
        phoneUrl: "0418900310",
        email: "benedicta_ankrah@penunderwriting.com",
        imageUrl: "Benedicta-Ankrah",
        location: "Sydney",
      },
      {
        name: "Corey Macri ",
        division: "Liability",
        role: "Senior Underwriter",
        mobile: "02 9323 5037",
        mobileUrl: "0293235037",
        phone: "0466 948 343",
        phoneUrl: "0466948343",
        email: "corey_macri@penunderwriting.com",
        imageUrl: "Corey-Macri",
        location: "Sydney",
      },
      {
        name: "David Hackforth",
        division: "Construction",
        role: "Underwriting Manager",
        mobile: "02 9323 5019",
        mobileUrl: "0293235019",
        phone: "0408 405 728",
        phoneUrl: "0408405728",
        email: "david_hackforth@penunderwriting.com",
        imageUrl: "David-Hackforth",
        location: "Sydney",
      },
      {
        name: "Dean West",
        division: "Construction",
        role: "National Construction & Engineering Manager",
        mobile: "02 9323 5029",
        mobileUrl: "0293235029",
        phone: "0468 542 032",
        phoneUrl: "0468542032",
        email: "dean_west@penunderwriting.com",
        imageUrl: "Dean-West",
        location: "Sydney",
      },
      {
        name: "Diva Mupnar",
        division: "Property",
        role: "Underwriter",
        mobile: "03 9810 0656",
        mobileUrl: "0398100656",
        phone: "0481 095 173",
        phoneUrl: "0481095173",
        email: "Diva_Mupnar@penunderwriting.com",
        imageUrl: "Diva-Mupnar",
        location: "Melbourne",
      },
      {
        name: "Ed Rooke",
        division: "Liability",
        role: "Technical Manager – Liability",
        mobile: "02 9323 5080",
        mobileUrl: "0293235080",
        phone: "0403 178 668",
        phoneUrl: "0403178668",
        email: "ed_rooke@penunderwriting.com",
        imageUrl: "Ed-Rooke",
        location: "Sydney",
      },
      {
        name: "Ely Romero",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "02 9323 5071",
        mobileUrl: "0293235071",
        phone: "0466 689 779",
        phoneUrl: "0466689779",
        email: "ely_romero@penunderwriting.com",
        imageUrl: "Ely-Romero",
        location: "Sydney",
      },
      {
        name: "Felipe Medina Campo",
        division: "Care Liability",
        role: "Underwriter",
        mobile: "03 9810 0610",
        mobileUrl: "0398100610",
        phone: "0466 537 639",
        phoneUrl: "0466537639",
        email: "felipe_medinacampo@penunderwriting.com",
        imageUrl: "Felipe-Campo",
        location: "Melbourne",
      },
      {
        name: "Francis Lee",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "03 9810 0606",
        mobileUrl: "0398100606",
        phone: "0479 181 446",
        phoneUrl: "0479181446",
        email: "Francis_Lee@penunderwriting.com",
        imageUrl: "Francis-Lee",
        location: "Melbourne",
      },
      {
        name: "Gary Beer",
        division: "Property",
        role: "Underwriter",
        mobile: "02 9323 5015",
        mobileUrl: "0293235015",
        phone: "0481 093 939",
        phoneUrl: "0481093939",
        email: "gary_beer@penunderwriting.com",
        imageUrl: "Gary-Beer",
        location: "Sydney",
      },
      {
        name: "Ian Warmer",
        division: "Construction",
        role: "Senior Underwriter",
        mobile: "02 9323 5039",
        mobileUrl: "0293235039",
        phone: "0418 900 794",
        phoneUrl: "0418900794",
        email: "ian_warmer@penunderwriting.com",
        imageUrl: "Ian-Warmer",
        location: "Sydney",
      },
      {
        name: "Jack Chia",
        division: "Financial Lines",
        role: "Senior Underwriter",
        mobile: "02 9323 5010",
        mobileUrl: "0293235010",
        phone: "0466 614 106",
        phoneUrl: "0466614106",
        email: "jack_chia@penunderwriting.com",
        imageUrl: "Jack-Chia",
        location: "Perth",
      },
      {
        name: "James Clay",
        division: "Transport",
        role: "Underwriter",
        mobile: "02 9323 5010",
        mobileUrl: "0293235010",
        phone: "0421 626 261",
        phoneUrl: "0421626261",
        email: "james_clay@penunderwriting.com",
        imageUrl: "James-Clay",
        location: "Sydney",
      },
      {
        name: "Jaimelee Quetua",
        division: "Property",
        role: "Underwriter",
        mobile: "07 3056 1431",
        mobileUrl: "0730561431",
        phone: "0481 395 721",
        phoneUrl: "0481395721",
        email: "jaimelee_quetua@penunderwriting.com",
        imageUrl: "Jaimelee-Quetua",
        location: "Sydney",
      },
      {
        name: "Jenny Prendergast",
        division: "Transport",
        role: "Senior Underwriter",
        mobile: "07 3056 1431",
        mobileUrl: "0730561431",
        phone: "0403 870 007",
        phoneUrl: "0403870007",
        email: "Jenny_Prendergast@penunderwriting.com",
        imageUrl: "Jenny-Prendergast",
        location: "Brisbane",
      },
      {
        name: "Jerome Furtado",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "02 9323 5072",
        mobileUrl: "02 9323 5072",
        phone: "0438 692 269",
        phoneUrl: "0438692269",
        email: "jerome_furtado@penunderwriting.com",
        imageUrl: "Jerome-Furtado",
        location: "Sydney",
      },
      {
        name: "Josephine Ho",
        division: "Liability",
        role: "Underwriter",
        mobile: "07 3056 1435",
        mobileUrl: "0730561435",
        phone: "0428 034 951",
        phoneUrl: "0428034951",
        email: "Josephine_Ho@penunderwriting.com",
        imageUrl: "Josephine-Ho",
        location: "Brisbane",
      },
      {
        name: "Karen Taylor",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "03 9810 0613",
        mobileUrl: "0398100613",
        phone: "0403 603 477",
        phoneUrl: "0403603477",
        email: "Karen_Taylor@penunderwriting.com",
        imageUrl: "Karen-Taylor",
        location: "Melbourne",
      },
      {
        name: "Katharine Vermont",
        division: "Property",
        role: "Underwriter",
        mobile: "03 9810 0609",
        mobileUrl: "0398100609",
        phone: "0478 269 525",
        phoneUrl: "0478269525",
        email: "Katharine_Vermont@penunderwriting.com",
        imageUrl: "Katharine-Vermont",
        location: "Melbourne",
      },
      {
        name: "Louise Soutter",
        division: "Financial Lines",
        role: "Underwriting Manager",
        mobile: "07 3056 1406",
        mobileUrl: "07 3056 1406",
        phone: "0481 058 703",
        phoneUrl: "0481058703",
        email: "Louise_Soutter@penunderwriting.com",
        imageUrl: "Louise-Soutter",
        location: "Brisbane",
      },
      {
        name: "Linda Gleave",
        division: "Care Liability",
        role: "Senior Underwriter ",
        mobile: "02 9323 5052",
        mobileUrl: "02 9323 5052",
        phone: "0427 153 228",
        phoneUrl: "0427153228",
        email: "Linda_Gleave@penunderwriting.com",
        imageUrl: "Linda-Gleave",
        location: "Sydney",
      },
      {
        name: "Luke Bartier",
        division: "Construction",
        role: "Assistant Underwriter",
        mobile: "0421 593 934 ",
        mobileUrl: "0421593934 ",
        phone: "0421 593 934 ",
        phoneUrl: "0421593934 ",
        email: "luke_bartier@penunderwriting.com",
        imageUrl: "Luke-Bartier",
        location: "Sydney",
      },
      {
        name: "Mary-Anne Turner",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "03 9810 0624",
        mobileUrl: "0398100624",
        phone: "0481 477 076",
        phoneUrl: "0481477076",
        email: "MaryAnne_Turner@penunderwriting.com",
        imageUrl: "Mary-Anne-Turner",
        location: "Melbourne",
      },
      {
        name: "Maryanne Stanton",
        division: "Property",
        role: "Underwriter",
        mobile: "03 9810 0619",
        mobileUrl: "0398100619",
        phone: "0435 399 769",
        phoneUrl: "0435399769",
        email: "Maryanne_Stanton@penunderwriting.com",
        imageUrl: "Maryanne-Stanton",
        location: "Melbourne",
      },
      {
        name: "Maz McLennan",
        division: "Property",
        role: "Underwriter",
        mobile: "07 3056 1407",
        mobileUrl: "0730561407",
        phone: "0419 512 894",
        phoneUrl: "0419512894",
        email: "Maz_McLennan@penunderwriting.com",
        imageUrl: "Maz-McLennan",
        location: "Brisbane",
      },
      {
        name: "Michael Kennedy",
        division: "Liability",
        role: "Underwriter",
        mobile: "02 9323 5024",
        mobileUrl: "0293235024",
        phone: "0403 258 780",
        phoneUrl: "00403258780",
        email: "Michael_Kennedy@penunderwriting.com",
        imageUrl: "Michael-Kennedy",
        location: "Sydney",
      },
      {
        name: "Nick Best",
        division: "Property",
        role: "Team Manager",
        mobile: "03 9810 0608",
        mobileUrl: "0398100608",
        phone: "0479 174 406",
        phoneUrl: "0479174406",
        email: "Nick_Best@penunderwriting.com",
        imageUrl: "Nick-Best",
        location: "Melbourne",
      },
      {
        name: "Patricia Karasova",
        division: "Liability",
        role: "Underwriter",
        mobile: "02 9323 5020",
        mobileUrl: "0293235020",
        phone: "0479 171 158",
        phoneUrl: "0479171158",
        email: "patricia_karasova@penunderwriting.com",
        imageUrl: "Patricia-Karasova",
        location: "Sydney",
      },
      {
        name: "Paul Behm",
        division: "Liability",
        role: "Team Manager",
        mobile: "02 9323 5020",
        mobileUrl: "0293235020",
        phone: "0414 950 122",
        phoneUrl: "0414950122",
        email: "Paul_Behm@penunderwriting.com",
        imageUrl: "Paul-Behm",
        location: "Sydney",
      },
      {
        name: "Rebecca Taylor",
        division: "Financial Lines",
        role: "Assistant Underwriter",
        mobile: "",
        mobileUrl: "",
        phone: "",
        phoneUrl: "",
        email: "rebecca_taylor1@penunderwriting.com",
        imageUrl: "Rebecca-Taylor",
        location: "Brisbane",
      },
      {
        name: "Rob Haward",
        division: "Liability",
        role: "Senior Underwritier",
        mobile: "07 3056 1450",
        mobileUrl: "0730561450",
        phone: "0488 413 231",
        phoneUrl: "0488413231",
        email: "Rob_Haward@penunderwriting.com",
        imageUrl: "Rob-Haward",
        location: "Brisbane",
      },
      {
        name: "Sarah Gerdes",
        division: "Liability",
        role: "Senior Underwriter",
        mobile: "02 9323 5017",
        mobileUrl: "0293235017",
        phone: "0403 952 181",
        phoneUrl: "0403952181",
        email: "Sarah_Gerdes@penunderwriting.com",
        imageUrl: "Sarah-Gerdes",
        location: "Sydney",
      },
      {
        name: "Sally Ing",
        division: "Property",
        role: " Underwriter ",
        mobile: "02 9323 5018",
        mobileUrl: "0293235018",
        phone: "0481 466 280",
        phoneUrl: "0481466280",
        email: "Sally_Ing@penunderwriting.com",
        imageUrl: "Sally-Ing",
        location: "Sydney",
      },
      {
        name: "Srashtee Lalwani",
        division: "Transport",
        role: "Underwriter",
        mobile: "02 9323 5014",
        mobileUrl: "0293235014",
        phone: "0481 094 939",
        phoneUrl: "0481094939",
        email: "Srashtee_Lalwani@penunderwriting.com",
        imageUrl: "Srashtee-Lalwani",
        location: "Sydney",
      },
      {
        name: "Styve Tebbutt",
        division: "Property",
        role: "Senior Underwriter",
        mobile: "07 3056 1440",
        mobileUrl: "0730561440",
        phone: "0466 170 601",
        phoneUrl: "0466170601",
        email: "Styve_Tebbutt@penunderwriting.com",
        imageUrl: "Styve-Tebbutt",
        location: "Brisbane",
      },
      {
        name: "Tim Moore",
        division: "Transport",
        role: "Team Manager",
        mobile: "03 9810 0615",
        mobileUrl: "0398100615",
        phone: "0417 565 636",
        phoneUrl: "0417565636",
        email: "Tim_Moore@penunderwriting.com",
        imageUrl: "Tim-Moore",
        location: "Melbourne",
      },
      {
        name: "Tanya Ommi",
        division: "Property",
        role: "Underwriter",
        mobile: "02 9323 5016",
        mobileUrl: "0293235016",
        phone: "0403 969 960",
        phoneUrl: "0403969960",
        email: "Tanya_Ommi@penunderwriting.com",
        imageUrl: "Tanya-Ommi",
        location: "Sydney",
      },
      {
        name: "Vanessa MacDonald",
        division: "Financial Lines",
        role: "Senior Underwriter",
        mobile: "02 9323 5024",
        mobileUrl: "0293235024",
        phone: "0440 100 777",
        phoneUrl: "0440100777",
        email: "vanessa_macdonald@penunderwriting.com",
        imageUrl: "Vanessa-MacDonald",
        location: "Melbourne",
      },
      {
        name: "Vanessa Zoricic",
        division: "Transport",
        role: "Underwriter",
        mobile: "02 9323 5024",
        mobileUrl: "0293235024",
        phone: "0468 655 750",
        phoneUrl: "0468655750",
        email: "vanessa_zoricic@penunderwriting.com",
        imageUrl: "Vanessa-Zoricic",
        location: "Melbourne",
      },
      {
        name: "Yulia Basuki",
        division: "Care Liability",
        role: "Underwriter",
        mobile: "02 9323 5084",
        mobileUrl: "0293235084",
        phone: "0478 264 754",
        phoneUrl: "0478264754",
        email: "Yulia_Basuki@penunderwriting.com",
        imageUrl: "Yulia-Basuki",
        location: "Sydney",
      },
    ],
    docs: [
      // Construction
      {
        title: "Annual Contract and Construction Liability Proposal Form",
        division: "Construction",
        type: "proposal",
        url: "construction/Fillable_PEN_Annual_Contract_and_Construction_Liability_Proposal_Form_2024.3(F).pdf",
      },
      {
        title:
          "Annual Contract Works and Construction Liability Renewal Declaration",
        division: "Construction",
        type: "declaration",
        url: "construction/Annual+Contract+Works+and+Construction+Liability+Renewal+Declaration+v1220.pdf",
      },
      {
        title: "Plant and Equipment Proposal",
        division: "Construction",
        type: "proposal",
        url: "construction/Plant+and+Equipment+Proposal+v1220_Fillable+Form.pdf",
      },
      {
        title: "Plant and Equipment Renewal Declaration",
        division: "Construction",
        type: "declaration",
        url: "construction/Plant+and+Equipment+Renewal+Declaration+v1220.pdf",
      },
      {
        title: "Single Contract Works Liability Proposal Form",
        division: "Construction",
        type: "proposal",
        url: "construction/Fillable_PEN-_Single_Contract_Works__Liability_Proposal_Form_v2024.3(F).pdf",
      },
      // Liability (Care)
      {
        title: "Aged Care Providers Combined Liability Application Form",
        type: "proposal",
        division: "Care Liability",
        url: "liability/care_liability/Aged+Care+Providers+Combined+Liability+Application+Form+v1024.pdf",
      },
      {
        title: "Home and Community Care Combined Liability Application Form",
        type: "proposal",
        division: "Care Liability",
        url: "liability/care_liability/Home+and+Community+Care+Combined+Liability+Application+Form+vL1024.pdf",
      },
      {
        title: "Sexual Abuse Addendum",
        type: "proposal",
        division: "Care Liability",
        url: "liability/care_liability/Sexual+Abuse+Addendum+vL03.24.pdf",
      },
      // Liability (General)
      {
        title: "Demolition and Asbestos Removal Liability Proposal",
        type: "proposal",
        division: "Liability",
        url: "liability/general_liability/Demolition+and+Asbestos+Removal+Liability+Proposal+v0121_Fillable+Form.pdf",
      },
      {
        title: "Errors and Omissions Extension Proposal",
        type: "proposal",
        division: "Liability",
        url: "liability/general_liability/Errors+and+Omissions+Extension+Proposal.pdf",
      },
      {
        title: "General Public and Products Liability",
        type: "proposal",
        division: "Liability",
        url: "liability/general_liability/General+Public+and+Products+Liability+v0121_Fillable+Form.pdf",
      },
      {
        title: "Mining Risks Proposal",
        type: "proposal",
        division: "Liability",
        url: "liability/general_liability/Mining+Risks+Proposal+v0121_Fillable+Form.pdf",
      },
      {
        title: "Products Liability Proposal",
        type: "proposal",
        division: "Liability",
        url: "liability/general_liability/Products+Liability+Proposal+v0121_Fillable+Forms.pdf",
      },
      // Liability (Hospitality)
      {
        title: "Accommodation Liability Proposal",
        type: "proposal",
        division: "Liability",
        url: "liability/hospitality_liability/Accommodation+Liability+Proposal+v1120_Fillable+Form.pdf",
      },
      {
        title: "Accommodation Liability Renewal Declaration",
        type: "declaration",
        division: "Liability",
        url: "liability/hospitality_liability/Accommodation+Liability+Renewal+Declaration+v1120._Fillable+Form.pdf",
      },
      {
        title: "Bistro Restaurant Proposal",
        type: "proposal",
        division: "Liability",
        url: "liability/hospitality_liability/Bistro+Restaurant+Proposal+v1120_Fillable+Form.pdf",
      },
      {
        title: "Bistro Restaurant Renewal Declaration",
        type: "declaration",
        division: "Liability",
        url: "liability/hospitality_liability/Bistro+Restaurant+Renewal+Declaration+v1120_Fillable+Form.pdf",
      },
      {
        title: "Caravan Park Liabilty Proposal",
        type: "proposal",
        division: "Liability",
        url: "liability/hospitality_liability/Caravan+Park+Liabilty+Proposal+v1120_Fillable+Form.pdf",
      },
      {
        title: "Caravan Park Renewal Declaration",
        type: "declaration",
        division: "Liability",
        url: "liability/hospitality_liability/Caravan+Park+Renewal+Declaration+v1120_Fillable+Form.pdf",
      },
      {
        title: "Golf Club Public Liability Insurance Proposal",
        type: "proposal",
        division: "Liability",
        url: "liability/hospitality_liability/Golf+Club+Public+Liability+Insurance+Proposal+v1120_Fillable+Form.pdf",
      },
      {
        title: "Golf Clubs Renewal Declaration",
        type: "declaration",
        division: "Liability",
        url: "liability/hospitality_liability/Golf+Clubs+Renewal+Declaration+v1120_Fillable+Form.pdf",
      },
      {
        title: "Licensed Club Proposal Form",
        type: "proposal",
        division: "Liability",
        url: "liability/hospitality_liability/Licensed+Club+Proposal+Form+v0821_Fillable+Form.pdf",
      },
      {
        title: "Licensed Club Renewal Declaration",
        type: "declaration",
        division: "Liability",
        url: "liability/hospitality_liability/Licensed+Club+Renewal+Declaration+v0821_Fillable+Form.pdf",
      },
      {
        title: "Licensed Premises Proposal Form",
        type: "proposal",
        division: "Liability",
        url: "liability/hospitality_liability/Licensed+Premises+Proposal+Form+v0821_Fillable+Form.pdf",
      },
      {
        title: "Licensed Premises Renewal Declaration",
        type: "declaration",
        division: "Liability",
        url: "liability/hospitality_liability/Licensed+Premises+Renewal+Declaration+v0821_Fillable+Form.pdf",
      },
      {
        title: "Property Owners Proposal",
        type: "proposal",
        division: "Liability",
        url: "liability/hospitality_liability/Property+Owners+Proposal+v1120_Fillable+Form.pdf",
      },
      {
        title: "Property Owners Renewal Declaration",
        type: "declaration",
        division: "Liability",
        url: "liability/hospitality_liability/Property+Owners+Renewal+Declaration+v1120_Fillable+Form.pdf",
      },
      {
        title: "Licensed Premises Renewal Declaration",
        type: "declaration",
        division: "Liability",
        url: "liability/hospitality_liability/Licensed+Premises+Renewal+Declaration+v0821_Fillable+Form.pdf",
      },
      // Property
      {
        title: "Commercial Property Proposal",
        type: "proposal",
        division: "Property",
        url: "property/Commercial+Property+Proposal+v0121_Fillable+Form.pdf",
      },
      {
        title: "Hospitality Proposal",
        type: "proposal",
        division: "Property",
        url: "property/Hospitality+Proposal+v0121.pdf",
      },
      {
        title: "Industrial Special Risks Proposal",
        type: "proposal",
        division: "Property",
        url: "property/Industrial+Special+Risks+Proposal+v0121_Fillable+Form.pdf",
      },
      {
        title: "Plastic Risks Questionnaire",
        type: "form",
        division: "Property",
        url: "property/Plastic+Risks+Questionnaire++v0121.pdf",
      },
      {
        title: "Property Vacant Risk Questionnaire",
        type: "form",
        division: "Property",
        url: "property/Property_Vacant_Risk_Questionnaire_V2024_6.pdf",
      },
      {
        title: "Property Backpacker and Boarding House Proposal",
        type: "proposal",
        division: "Property",
        url: "property/Property+Backpacker+and+Boarding+House+Proposal+v0121.pdf",
      },
      {
        title: "Property Cooking Questionnaire",
        type: "form",
        division: "Property",
        url: "property/Property+Cooking+Questionnaire+v0121_Fillable+Form.pdf",
      },
      {
        title: "Property Survey",
        type: "form",
        division: "Property",
        url: "property/Property+Survey+v0121.pdf",
      },
      {
        title: "Property Woodworking Questionaire",
        type: "form",
        division: "Property",
        url: "property/Property+Woodworking+Questionaire+v0121.pdf",
      },
      // Transport
      {
        title: "Dangerous Goods Questionnaire",
        type: "form",
        division: "Transport",
        url: "transport/Dangerous+Goods+Questionnaire+v1020.pdf",
      },
      {
        title: "Driver Declaration",
        type: "proposal",
        division: "Transport",
        url: "transport/Driver+Declaration+v1020.pdf",
      },
      {
        title: "Motor Vehicle Quotation Form",
        type: "proposal",
        division: "Transport",
        url: "transport/Motor+Vehicle+Quotation+Form+v062022.pdf",
      },
      // Financial Lines (formerly Professional Risk)
      {
        title: "Financial Lines Design and Engineering Proposal Form",
        type: "proposal",
        division: "Financial Lines",
        url: "financial_lines/Financial+Lines+Design+and+Engineering+Proposal+Form+as+of+1+Oct+2024.pdf",
      },
      {
        title: "Financial Lines Professional Indemnity Proposal Form",
        type: "proposal",
        division: "Financial Lines",
        url: "financial_lines/Financial+Lines+Professional+Indemnity+Proposal+Form.pdf",
      },
      {
        title: "Financial Lines Real Estate Agents Proposal Form",
        type: "proposal",
        division: "Financial Lines",
        url: "financial_lines/Financial+Lines+Real+Estate+Agents+Proposal+Form.pdf",
      },
    ],
  },
  analytics: {
    gacode: "G-MV1LDD44WJ",
    gaprod: "G-MV1LDD44WJ",
  },
};
